import React, { memo } from 'react';
import classnames from 'classnames';
import './HamburgerButton.scss';

const HamburgerButton = ({ clickHandler, isOpen }) => {
  const classes = classnames('hamburger', 'u-tab-focus-only', {
    open: isOpen
  });
  return (
    <span
      className={classes}
      role="button"
      aria-haspopup="true"
      aria-label="Navigation"
      tabIndex={0}
      onKeyPress={clickHandler}
      onClick={clickHandler}
    />
  );    
};

export default memo(HamburgerButton);
