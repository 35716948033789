import React from "react";
import { Link } from "gatsby";

const GatsbyLink = ({ children, to, activeClassName, partiallyActive, ...other }) => {
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <Link to={to} activeClassName={activeClassName} partiallyActive={partiallyActive} {...other}>
        {children}
      </Link>
    );
  }
  // External
  return (
    <a href={to} rel="noopener noreferrer" {...other}>
      {children}
    </a>
  );
};

export default GatsbyLink;
