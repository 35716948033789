import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import LogoLight from '../../assets/svgs/header_logo_light.svg';
import LogoDark from '../../assets/svgs/header_logo_dark.svg';
import LogoRed from '../../assets/svgs/footer_logo.svg';
// import LogoRed from '../../assets/svgs/header_logo_brand.svg';
import Footer from '../Footer/Footer';
import NavBar from '../NavBar/NavBar';
import { useNavBarScrollState } from 'utils/custom-hooks/nav-bar-hooks';
import './Layout.scss';

const Logos = {
  red: LogoRed,
  light: LogoLight,
  dark: LogoDark
};

const Themes = {
  default: '',
  dark: 'Header--dark'
};

const LinkThemes = {
  dark: 'Links--gray',
  light: 'Links--white'
};


/**
 * Get the correct logo and theme.
 */
const getThemeAndLogo = ({logo = 'red', type = 'default', header = 'dark'}) => {
  return {
    themeType: Themes[type],
    logoType: Logos[logo],
    linkType: LinkThemes[header]
  };
};

const Layout = ({ children, theme, bodyClass = '' }) => {
  const themeConfig = theme || {};
  const { themeType, logoType, linkType } = getThemeAndLogo(themeConfig);
  const ref = useRef();
  const scrollState = useNavBarScrollState(ref);
  const [navDetector, setNavDetector] = useState(themeType);
  const [linkTheme, setLinkTheme] = useState(linkType);
  const [logoSrc, setLogo] = useState(logoType);
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const { themeType, logoType, linkType } = getThemeAndLogo(themeConfig);
    setNavDetector(themeType);
    setLinkTheme(linkType);
    setLogo(logoType);
  });

  return (
    <div id="GatsbyBody" className={classNames(bodyClass, { NoScroll: isOpen })}>
      <NavBar
        ref={ref}
        {...scrollState}
        isOpen={isOpen}
        toggleHandler={toggleIsOpen}
        theme={navDetector}
        linkTheme={linkTheme}
        logo={logoSrc}
      />
      <main id="main-content" className="main" role="main">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
