/**
 * See http://greweb.me/2012/02/bezier-curve-based-easing-functions-from-concept-to-implementation/
 */
import BezierEasing from 'bezier-easing';

const ease = {
  base: new BezierEasing(0.25, 0.1, 0.25, 1),
  linear: new BezierEasing(0.25, 0.1, 0.25, 1),
  in: new BezierEasing(0.42, 0, 1, 1),
  out: new BezierEasing(0, 0, 0.58, 1),
  inOut: new BezierEasing(0.42, 0, 0.58, 1),
  inSine: new BezierEasing(0.47, 0, 0.745, 0.715),
  outSine: new BezierEasing(0.39, 0.575, 0.565, 1),
  inOutSine: new BezierEasing(0.445, 0.05, 0.55, 0.95),
  inQuad: new BezierEasing(0.55, 0.085, 0.68, 0.53),
  outQuad: new BezierEasing(0.25, 0.46, 0.45, 0.94),
  inOutQuad: new BezierEasing(0.455, 0.03, 0.515, 0.955),
  inCubic: new BezierEasing(0.55, 0.055, 0.675, 0.19),
  outCubic: new BezierEasing(0.215, 0.61, 0.355, 1),
  inOutCubic: new BezierEasing(0.645, 0.045, 0.355, 1),
  inQuart: new BezierEasing(0.895, 0.03, 0.685, 0.22),
  outQuart: new BezierEasing(0.165, 0.84, 0.44, 1),
  inOutQuart: new BezierEasing(0.77, 0, 0.175, 1),
  inQuint: new BezierEasing(0.755, 0.05, 0.855, 0.06),
  outQuint: new BezierEasing(0.23, 1, 0.32, 1),
  inOutQuint: new BezierEasing(0.86, 0, 0.07, 1),
  inExpo: new BezierEasing(0.95, 0.05, 0.795, 0.035),
  outExpo: new BezierEasing(0.19, 1, 0.22, 1),
  inOutExpo: new BezierEasing(1, 0, 0, 1),
  inCirc: new BezierEasing(0.6, 0.04, 0.98, 0.335),
  outCirc: new BezierEasing(0.075, 0.82, 0.165, 1),
  inOutCirc: new BezierEasing(0.785, 0.135, 0.15, 0.86),
  snap: new BezierEasing(0.75, -0.5, 0, 1.75),
  custom: (x1, y1, x2, y2) => new BezierEasing(x1, y1, x2, y2),
};

export default ease;
