import React from "react";

const Section = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <section ref={ref} {...props}>
      {children}
    </section>
  );
});

export default Section;
