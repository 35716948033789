/**
 * Core utilities
 */
export const isBrowser = typeof window !== 'undefined';

export const getScrollTop = () => {
  return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
};

export const getMarginTop = () => {
  const elemStyle = window.getComputedStyle(document.body);
  return parseFloat(elemStyle.getPropertyValue('margin-top').slice(0, -2), 10);
};

