import React from 'react';
import { Link } from 'gatsby';
import LogoDark from '../../assets/svgs/header_logo_dark.svg';
import './Logo.scss';

/**
 * Gets the required logo based on theme and stick nav info.
 *
 * TODO (joshua): Also pass in dims for determining mobile
 */
 const getLogo = (theme, logo, isSticky) => {
  const src = (theme === 'Header--dark' || isSticky) ? LogoDark : logo;
  return <img src={src} className="logo" alt="Site Logo" />;
}

const Logo = ({ theme, logo, isSticky }) => {
  return (
    <div className="logo-container">
      <Link to="/">
        {getLogo(theme, logo, isSticky)}
        <img src={LogoDark} className="logo mobile" alt="Site Logo" />
      </Link>
  </div>    
  )
};

export default Logo;