import { useCallback, useRef } from 'react';
import { TweenMax } from 'gsap';
import ease from 'utils/animations/ease';
import useGsap from './use-gsap-hook';

const fadeIn = ( axis, translateLength, triggerRef, animateInOptions = {} ) => {
  const ref = useRef();

  const {
    duration = 1.15,
    stagger = 0.06,
    ...extraOptions
  } = animateInOptions;

  const animateInit = useCallback(() => {
    TweenMax.set(
      ref.current,
      {
        opacity: 0.01,
        [axis]: translateLength,
      }
    );
  }, [ref]);

  const animateIn = useCallback(() => {
    TweenMax.staggerTo(
      ref.current,
      duration,
      {
        opacity: 1,
        ease: ease.in,
        delay: 0.13,
        ...extraOptions
      },
      stagger,
    );

    TweenMax.staggerTo(
      ref.current,
      // The translation is always faster
      duration * 0.5,
      {
        [axis]: 0,
        ease: ease.inOutQuad,
        delay: 0.13,
        ...extraOptions,
      },
      stagger,
    );

  }, [ref]);

  useGsap({ ref: triggerRef || ref, animateIn, animateInit });

  return [ ref, animateIn ];
};

export default fadeIn;
