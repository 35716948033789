import { useEffect } from 'react';
import useIntersectionObserver from 'utils/use-intersection-observer-hook';
import { shouldAnimate } from 'utils/constant';

/**
 * React hook that return is the object is intersected
 */
function useGsap({ ref, animateIn, animateInit }, config = {}, rootRef = null) {
  // TODO: add browser flag later
  const intersected = useIntersectionObserver(ref, config, rootRef);

  useEffect(() => {
    if (shouldAnimate()) {
      animateInit();
    }
  }, [animateInit]);

  useEffect(() => {
    if (shouldAnimate()) {
      if (intersected) {
        animateIn();
      }
    }
  }, [intersected, animateIn]);

  return intersected;
}

export default useGsap;
