import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import GatsbyLink from "../GatsbyLink/GatsbyLink";
import fadeIn from 'utils/animations/fade-in';

import "./Button.scss";

const Button = ({ children, className, variant = "light", ...others }) => {
  const [ ref ] = fadeIn('y', 0);
  return (
    <div ref={ref} className="alpha0">
      <GatsbyLink
        role="Button"
        className={classNames("Button u-tab-focus-only", variant)}
        {...others}
      >
        {children}
      </GatsbyLink>
    </div>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["light", "dark"]),
};

export default Button;
