import { useRef, useState, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { isBrowser, getScrollTop, getMarginTop } from 'utils/core';
import { useWindowScroll } from 'utils/custom-hooks/window-hooks';

const defaultThrottle = 300;

const initialState = { 
  scrollingUp: false, 
  isSticky: false 
};

/* eslint-disable react-hooks/rules-of-hooks */
/* early return should happen as it is expected to run only in browser */
export const useNavBarScrollState = (ref, throttle = defaultThrottle) => {
  if (!isBrowser) {
    return initialState;
  }

  const lastScrollY = useRef(getScrollTop());
  const didBeginToScrollUp = useRef(false);
  const lastMarginBodyTop = useRef(getMarginTop());
  const isTopNavInViewWhenBeginToScrollUp = useRef(true);
  const [state, setState] = useState(initialState);

  const handleScroll = useDebouncedCallback(
    useCallback(() => {
      if (!ref.current) {
        return;
      }

      const scrollY = getScrollTop();
      // body has margin top when scroll lock on body (when nav is open)
      const bodyMarginTop = getMarginTop(); 

      const isAtTheTop = scrollY === 0 && bodyMarginTop === 0;

      // scrollY is 0 and a negative margin equal to the scroll amount is added 
      // to the body when scroll is locked
      const hasScrollChanged = scrollY - bodyMarginTop !== (
        lastScrollY.current - lastMarginBodyTop.current);

      const scrollingUp = !hasScrollChanged || scrollY < lastScrollY.current;
      lastScrollY.current = scrollY;
      lastMarginBodyTop.current = bodyMarginTop;

      if (isAtTheTop) {
        didBeginToScrollUp.current = false;
      }

      if (!state.scrollingUp && scrollingUp) {
        didBeginToScrollUp.current = true;
        const { offsetHeight } = ref.current;
        isTopNavInViewWhenBeginToScrollUp.current = scrollY <= offsetHeight;
      }

      const isSticky =
        (!hasScrollChanged && state.isSticky) ||
        (didBeginToScrollUp.current && !isTopNavInViewWhenBeginToScrollUp.current);

      if (state.scrollingUp !== scrollingUp || state.isSticky !== isSticky) {
        setState({ scrollingUp, isSticky });
      }
    }, [ref, state.isSticky, state.scrollingUp]),
    throttle,
    { maxWait: throttle },
  );

  useWindowScroll(handleScroll);

  return state;
};
