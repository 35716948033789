import React from "react";
import twitter from "../../assets/svgs/twitter.svg";
import linkedin from "../../assets/svgs/linkedin.svg";
// import github from "../../assets/svgs/github.svg";
// import instagram from "../../assets/svgs/insta.svg";
import "./SocialLinks.scss";

const SocialIcons = [{
  icon: twitter,
  title: 'Twitter social',
  link: 'https://twitter.com/workwithvivid'
},{
  icon: linkedin,
  title: 'LinkedIn social',
  link: 'https://www.linkedin.com/company/vividcg'
// },{
//   icon: instagram,
//   title: 'Instagram social',
//   link: 'https://instagram.com/dfuzrindustries'
}];

const SocialLinks = () => {
  return (
    <div className="SocialMenu">
      {SocialIcons.map((socialIcon, i) => {
        return (
          <a key={i} href={socialIcon.link} target="_blank" title={socialIcon.title}>
            <img src={socialIcon.icon} alt={socialIcon.title} />
          </a>
        );
      })}
    </div>
  );
};

export default SocialLinks;
