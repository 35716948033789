import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import HamburgerButton from 'components/HamburgerButton/HamburgerButton';
import Logo from 'components/Logo/Logo';
import SocialLinks from '../SocialLinks/SocialLinks';
import './NavBar.scss';

const NavLinks = [{
  path: '/',
  label: 'Home'
},{
  path: '/about/',
  label: 'About'
},{
  path: '/work/',
  label: 'Work'
},{
  path: '/insights/',
  label: 'Insights'
},{
  path: '/contact/',
  label: 'Contact'
}];

const NavBar = ({
  theme,
  linkTheme,
  logo,
  toggleHandler,
  isOpen,
  scrollingUp,
  isSticky
}, ref) => {

  const classes = classNames('TopNav', theme, {
    scrollingUp,
    isSticky
  });
  const linkClasses = classNames('TopNav-navLists', linkTheme);

  return (
    <header id="Header" className={classes}>
      <div className="TopNavContainer">
        <Logo theme={theme} logo={logo} isSticky={isSticky} />
        <HamburgerButton isOpen={isOpen} clickHandler={toggleHandler} />
        <div className={classNames('TopNav-links', { Collapsed: isOpen })}>
          <nav ref={ref} className="TopNav-nav">
            <ul className={linkClasses}>
              {NavLinks.map((menuItem, i) => {
                return (
                  <li className="ListItem" key={i + menuItem.path}>
                    <Link to={menuItem.path} activeClassName="active">
                      {menuItem.label}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
          <SocialLinks />
        </div>
      </div>
    </header>
  );
};

export default forwardRef(NavBar);
