import React from "react";
import FooterLogo from "../../assets/svgs/footer_logo.svg";
import SocialLinks from "../SocialLinks/SocialLinks";
import Button from "../Button/Button";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="Footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="order-3 order-lg-1 col-lg-3">
            <SocialLinks />
          </div>
          <div className="Text--center order-sm-1 order-lg-2 col-lg-6">
            <p className="Text--center mb-4">
              <img src={FooterLogo} className="Footer-logo" alt="Vivid Logo" />
            </p>

            <p className="Address mb-4">
              Vivid Consulting Group,
              <br />
              1971 8th St. Boulder, CO 80302
            </p>
          </div>
          <div className="Text--right mText--center order-sm-2 order-lg-3 col-lg-3">
            <Button to="/contact/" variant="light" title="Contact">
              Contact
            </Button>
          </div>
        </div>
      </div>
      <div className="copyRights">
        <div className="container">
          <div className="row">
            <div className="d-none d-sm-none d-lg-block col-lg-6">
              © {new Date().getFullYear()}, VIVID CONSULTING GROUP
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
