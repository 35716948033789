import { useEffect } from 'react';

const useWindowEvent = (event, callback) => {
  useEffect(() => {
    window.addEventListener(event, callback);
    return () => window.removeEventListener(event, callback);
  }, [event, callback]);
};

export const useWindowScroll = (callback) => {
  return useWindowEvent('scroll', callback);
};

export const useWindowClick = (callback) => {
  return useWindowEvent('click', callback);
};
